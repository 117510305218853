<template>
  <v-app>
    <v-app-bar app>
      <v-container class="d-flex px-0 px-sm-4 flex-row align-center flex-nowrap">
        <div class="mr-md-8">
          <a class="logo d-block" :href="MAIN_DOMAIN" title="PROSTOR"></a>
        </div>
        <v-spacer></v-spacer>
        <div class="d-none d-sm-inline-block mx-8">
          <a class="primary--text text-h6 header-link text-nowrap" href="tel:+78122070007">+7 (812) 207-00-07</a>
        </div>
        <div class="d-inline-block">
          <v-btn
              v-if="user.email"
              :icon="$vuetify.breakpoint.smAndDown"
              @click="logout"
              color="primary"
              :outlined="!$vuetify.breakpoint.smAndDown"
              :loading="exitLoading"
              :disabled="exitLoading"
          >
            <v-icon
                v-if="$vuetify.breakpoint.mdAndDown"
            >mdi-exit-to-app
            </v-icon>
            <span v-if="!$vuetify.breakpoint.mdAndDown">Выйти</span>
          </v-btn>
        </div>
        <change-password v-if="user.email" class="d-inline-block"/>
      </v-container>
    </v-app-bar>
    <v-main>
      <v-container class="main-container">
        <slot name="content"/>
      </v-container>
    </v-main>
  </v-app>
</template>
<style scoped>
@media screen and (max-width: 600px) {
  .main-container {
    padding: 0;
  }
}

.logo {
  background: url(/img/logo.svg) center no-repeat;
  background-size: contain;
  align-self: stretch;
  margin: 0;
  flex: 0;
  min-width: 110px;
  min-height: 60px;
}

@media screen and (min-width: 960px) {
  .logo {
    margin: 0;
  }
}

.text-nowrap {
  white-space: nowrap !important;
}

.header-link {
  color: black;
  text-decoration: none;
  width: 100%
}
</style>
<script>
import Vue from "vue";
import {mapActions, mapState} from "vuex";
import ChangePassword from "@/components/ChangePassword";

export default Vue.extend({
  name: "MainLayout",
  components: {ChangePassword},
  data() {
    return {
      exitLoading: false,
      MAIN_DOMAIN: process.env.VUE_APP_MAIN_DOMAIN,
    };
  },
  mounted() {
    document.querySelectorAll(".logout").forEach(item => item.addEventListener("click", this.logout));
  },
  computed: {
    ...mapState({
      user: state => state.profile.user,
    }),
  },
  methods: {
    async logout() {
      this.exitLoading = true;
      await this.apiLogout();
      document.location.href = this.MAIN_DOMAIN;
      this.exitLoading = false;
    },
    ...mapActions({
      apiLogout: "profile/logout",
    }),
  },
});
</script>
