<template>
  <v-list v-if="documents.length">
    <div class="pa-4 px-sm-0 d-flex align-center">
      <div class="flex-shrink-0 flex-grow-1 text-h4">
        Ваши документы
      </div>
    </div>
    <v-divider></v-divider>
    <div
        v-for="(document) in documents"
        :key="document.id"
    >
      <v-list-item>
        <v-list-item-avatar v-if="$vuetify.breakpoint.mdAndUp"></v-list-item-avatar>
        <v-list-item-content class="box-list">
          <v-list-item-title>
            {{ document.filename }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ date.isoToHuman(document.created_at) }}
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            <a :href="`/storage/${document.path}`" class="pdf-link" target="_blank" rel="nofollow">
              <v-icon color="primary">
                mdi-pdf-box
              </v-icon>
              Скачать документ
            </a>

          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider :inset="$vuetify.breakpoint.mdAndUp"></v-divider>
    </div>
  </v-list>
</template>
<script>
import date from "@/lib/date";
import DocumentRepository from "@/repository/document";

export default {
  name: "ListDocuments",
  data() {
    return {
      documents: [],
      date,
    };
  },
  async mounted() {
    this.documents = await DocumentRepository.list();
  },
};
</script>
