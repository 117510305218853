import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import Profile from "@/views/Profile.vue";
import Redirect from "@/views/Redirect.vue";
import Authorization from "@/views/Authorization";
import Payment from "@/views/Payment";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        beforeEnter: () => {
            window.location.href = process.env.VUE_APP_MAIN_DOMAIN;
        },
    },
    {
        path: "/profile/:entity?/:entityId?",
        name: "Profile",
        component: Profile,
        async beforeEnter(to, from, next) {
            if (!store.state.profile.user.email) {
                await store.dispatch("profile/me");
            }
            next();
        },
    },
    {
        path: "/pay/sberbank/success",
        name: "SberSuccess",
        component: Payment,
        props: {
            type: "success",
        },
    },
    {
        path: "/pay/sberbank/fail",
        name: "SberFail",
        component: Payment,
        props: {
            type: "fail",
        },
    },
    {
        path: "/pay/tinkoff/success/:orderId",
        name: "TinkoffSuccess",
        component: Payment,
        props: {
            type: "success",
        },
    },
    {
        path: "/pay/tinkoff/fail/:orderId",
        name: "TinkoffFail",
        component: Payment,
        props: {
            type: "fail",
        },
    },
    {
        path: "/pay/qiwi/success/:orderId",
        name: "QiwiSuccess",
        component: Payment,
        props: {
            type: "success",
        },
    },
    {
        path: "/pay/yoo/success",
        name: "YooSuccess",
        component: Payment,
        props: {
            type: "success",
        },
    },
    {
        path: "/pay/yoo/fail",
        name: "YooFail",
        component: Payment,
        props: {
            type: "fail",
        },
    },
    {
        path: "/login",
        name: "Login",
        component: Authorization,
        props: true,
    },
    {
        path: "/forgot",
        name: "ForgotPassword",
        component: Authorization,
        props: {
            type: "forgot",
        },
    },
    {
        path: "/password/reset/:token",
        name: "RestorePassword",
        component: Authorization,
        props: {
            type: "reset",
        },
    },
    {
        path: "/restore",
        name: "RestoreUser",
        component: Authorization,
        props: {
            type: "restore",
        },
    },
    {
        path: "/redirect/:path+",
        name: "Redirect",
        component: Redirect,
    },
];

export default new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});
