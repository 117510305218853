<template>
  <main-layout/>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";
import {mapActions} from "vuex";

export default {
  name: "Redirect",
  components: [
      MainLayout,
  ],
  async mounted() {
    let token = "";
    new URL(document.location).searchParams.forEach(function (val, key) {
      if (key === 'token')
        token = val
    });
    if (token)
      await this.token({token: token});
    this.$router.push(this.$route.params.path ? "/"+this.$route.params.path : "/profile")
  },
  methods: {
    ...mapActions({
      token: "profile/token",
    }),
  },
};
</script>
