<template>
  <dialog-card
      max-width="450"
      v-model="value"
      @input="input($event)"
  >
    <template #title>
      Загрузить УПД
    </template>
    <template #actions>
      <v-btn
          color="primary"
          text
          @click="input(false)"
      >
        Закрыть
      </v-btn>
    </template>
    <template #activator="{ on, attrs }">
      <v-btn
          outlined
          color="primary"
          class="ma-3 float-right"
          v-bind="attrs"
          v-on="on"
      >
        Загрузить УПД
      </v-btn>
    </template>
    <div class="d-flex align-center">
      <v-file-input
          v-model="files"
          label="Документы"
          small-chips
          multiple
      />
      <v-btn
          color="primary"
          icon
          :disabled="!files.length"
          :loading="loading"
          @click="addMultiDocuments"
      >
        <v-icon>mdi-upload</v-icon>
      </v-btn>
    </div>
    <v-list>
      <v-subheader v-if="errors.length">Невозможно сохранить документы:</v-subheader>
      <div
          v-for="(filename, idx) in errors"
          :key="idx"
      >
        <v-divider/>
        <v-list-item>
          <v-list-item-title>{{ filename }}</v-list-item-title>
        </v-list-item>
      </div>
    </v-list>
    <alert-banner v-model="uploadError"/>
  </dialog-card>
</template>

<script>
import {mapActions} from "vuex";
import DialogCard from "@/components/DialogCard";
import AlertBanner from "@/components/AlertBanner";

export default {
  name: "MultiUpload",
  components: {AlertBanner, DialogCard},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      files: [],
      errors: [],
      loading: false,
      uploadError: '',
    };
  },
  methods: {
    async addMultiDocuments() {
      this.loading = true;
      this.uploadError = '';
      const data = new FormData();
      this.files.forEach(file => data.append(String(Math.random()), file));
      try {
        this.errors = await this.uploadMultiDocuments({data});
        this.files = [];
      } catch (e) {
        this.uploadError = 'Невозможно загрузить файлы';
      }
      this.loading = false;
    },
    input($event) {
      this.loading = false;
      this.errors = [];
      this.files = [];
      this.$emit("input", $event);
    },
    ...mapActions({
      uploadMultiDocuments: "admin/saveManyDocuments",
    }),
  },
};
</script>
