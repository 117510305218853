<template>
  <v-list>
    <div class="pa-4 px-sm-0 d-flex align-center">
      <div class="flex-shrink-0 flex-grow-1 text-h4">
        Ваши боксы
      </div>
      <checkout-dialog v-if="checkoutDialog" v-model="checkoutDialog" :can-logout="false" @paid="loadData"/>
      <v-btn
          :fab="$vuetify.breakpoint.xsOnly"
          depressed
          color="primary"
          @click="checkoutDialog = true"
      >
        <span v-if="!$vuetify.breakpoint.xsOnly">арендовать еще</span>
        <v-icon v-if="$vuetify.breakpoint.xsOnly">
          mdi-cart-plus
        </v-icon>
      </v-btn>
    </div>
    <v-divider></v-divider>
    <v-progress-linear
        v-if="listLoading"
        indeterminate
        color="primary darken-2"
    />
    <div
        v-for="(facilityCode) in Object.keys(unitsList) "
        :key="facilityCode"
    >
      <v-subheader class="pt-5 px-4 px-sm-0">
        <div class="flex-grow-1 flex-shrink-0 text-h6">{{ getFacilityTitle(facilityCode) }}</div>
        <v-btn
            @click="sendPin(facilityCode)"
            outlined
            color="primary"
            :loading="pinLoading"
            :disabled="pinLoading"
            small>
          пин-код
        </v-btn>
        <v-dialog max-width="500" v-model="pinDialog">
          <v-card>
            <v-card-text class="pt-6">
              <p>Ваш пин-код: <span class="text-h5">*{{ payment.pin }}#</span></p>
              <p>Пин-код действителен для всех Ваших боксов по адресу <span class="text-h6">{{ payment.address }}</span>.</p>
            </v-card-text>
            <v-divider></v-divider>
            <v-spacer></v-spacer>
            <div class="py-3 px-6">
              <v-btn color="primary" text @click="pinDialog = false">Хорошо</v-btn>
            </div>
          </v-card>
        </v-dialog>
      </v-subheader>
      <div
          v-for="(item) in unitsList[facilityCode]"
          :key="item.contractId"
      >
        <v-list-item class="pr-sm-0">
          <v-list-item-avatar v-if="$vuetify.breakpoint.mdAndUp"></v-list-item-avatar>
          <v-list-item-content class="box-list">
            <v-list-item-title>
              {{ item.unit }}
            </v-list-item-title>
            <v-list-item-subtitle v-html="getUnitTitle(facilityCode, item.unitType)"></v-list-item-subtitle>
            <v-list-item-subtitle class="d-flex flex-column" v-if="false">
              <a href="#" @click.stop.prevent="downloadContract(facilityCode, item.contractId)" class="pdf-link" target="_blank" rel="nofollow">
                <v-icon color="primary">
                  mdi-pdf-box
                </v-icon>
                Скачать договор
              </a>
              <a
                  v-if="item.insurance"
                  href="#" @click.stop.prevent="downloadInsurance(facilityCode, item.contractId)" class="pdf-link" target="_blank" rel="nofollow"
              >
                <v-icon color="primary">
                  mdi-pdf-box
                </v-icon>
                Скачать полис
              </a>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action
              class="flex-sm-row"
          >
            <div v-if="item.subscription || hasEndDate(facilityCode, item.unit, invoices)">
              <v-switch
                  v-if="item.subscription"
                  class="col-12"
                  :input-value="item.subscription && item.subscription.next"
                  :ref="`switch-${item.contractNumber}-${item.siteCode}`"
                  @change="changeSubscription(item)">
                <template v-slot:label>
                  <div class="d-block pl-2 switch-label">
                    <div>Автоплатеж</div>
                    <div v-if="item.subscription">{{ date.isoToHuman(item.subscription.next) }}</div>
                  </div>
                </template>
              </v-switch>
              <v-chip
                  v-else
                  small
                  :color="`lighten-3 ${isUnitExpired(facilityCode, item.unit) ? 'red' : 'grey'}`"
              >
                <span class="d-none d-md-inline-block pr-1">оплачено</span>
                <span>до: {{ date.toHuman(expires[facilityCode + "/" + item.unit]) }}</span>
              </v-chip>
            </div>
            <v-btn
                color="error"
                outlined
                class="mt-4 mt-sm-0 ml-sm-6 px-2 py-4"
                small
                text
                @click="unit = `${facilityCode}/${item.unit}`; unitDialog = true"
                :loading="loading"
                :disabled="loading">
              Завершить<br>аренду
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider :inset="$vuetify.breakpoint.mdAndUp"></v-divider>
      </div>
    </div>
    <v-dialog max-width="300" v-model="subscriptionDialog">
      <v-card>
        <v-card-title class="headline">
          Отмена подписки
        </v-card-title>
        <v-card-text>
          <p>Отменить автоплатеж?</p>
          <p>Платеж, назначенный на {{ date.isoToHuman(subscription.next) }} выполнен не будет.</p>
        </v-card-text>
        <v-divider></v-divider>
        <div class="py-3 px-6">
          <v-btn
              color="primary"
              outlined
              @click="cancelSubscription"
              :loading="loading"
              :disabled="loading">
            Да
          </v-btn>
          <v-btn
              color="primary"
              text
              @click="subscriptionDialog = false"
          >
            Нет
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog max-width="300" v-model="unitDialog">
      <v-card>
        <v-card-title class="headline">
          Завершение аренды
        </v-card-title>
        <v-card-text>
          <p>Убедитесь, что не забыли вещи и на боксе не висит замок.</p>
        </v-card-text>
        <v-divider></v-divider>
        <div class="py-3 px-6">
          <v-btn
              color="primary"
              outlined
              @click="cancelUnit"
              :loading="loading"
              :disabled="loading">
            Завершить
          </v-btn>
          <v-btn
              color="primary"
              text
              @click="unitDialog = false"
          >
            Отмена
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog max-width="300" v-model="unitConfirmDialog">
      <v-card>
        <v-card-title class="headline">
          Завершение аренды
        </v-card-title>
        <v-card-text>
          <p>ОК! Мы получили вашу заявку и закроем договор. Ваш бокс действует до конца оплаченного периода. Спасибо что были с нами и до новых встреч! 🙏</p>
        </v-card-text>
        <v-divider></v-divider>
        <div class="py-3 px-6">
          <v-btn
              color="primary"
              outlined
              @click="unitConfirmDialog = false"
          >
            Закрыть
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </v-list>
</template>
<style scoped>
.switch-label {
  font-size: small;
}
</style>
<script>
import {mapState} from "vuex";
import date from "@/lib/date";
import CONFIG from "@/config";
import CheckoutDialog from "@/components/CheckoutDialog";
import FacilityRepository from "@/repository/facility";
import UnitRepository from "@/repository/unit";
import InvoiceRepository from "@/repository/invoice";
import SubscriptionRepository from "@/repository/subscription";
import CustomerRepository from "@/repository/customer";

export default {
  name: "ListUnits",
  components: {
    CheckoutDialog,
  },
  data() {
    return {
      checkoutDialog: false,
      subscriptionDialog: false,
      unitDialog: false,
      unitConfirmDialog: false,
      pinDialog: false,
      pinLoading: false,
      subscriptions: [],
      payment: {},
      units: [],
      expires: [],
      invoices: [],
      subscription: {},
      unit: "",
      loading: false,
      listLoading: false,
      date,
      CONFIG,
    };
  },
  async mounted() {
    await FacilityRepository.list();
    await this.loadData();
    this.subscriptions = await SubscriptionRepository.list();
  },
  computed: {
    ...mapState({
      user: state => state.profile.user,
    }),
    unitsList() {
      return this.units.reduce((list, curr) => {
            curr.subscription = this.subscriptions.find(subscription => subscription.next && curr.unit == subscription.unit && curr.siteCode == subscription.facility_code);
            list[curr.siteCode] ||= [];
            list[curr.siteCode].push(curr);
            return list;
          },
          {});
    },
  },
  methods: {
    async loadData() {
      this.listLoading = true;
      this.units = await UnitRepository.list();
      this.invoices = await InvoiceRepository.list();
      this.listLoading = false;
    },
    async sendPin(key) {
      this.pinLoading = true;
      try {
        this.payment = await CustomerRepository.sendPin(key);
        this.pinDialog = true;
      } catch (e) {
        //
      }
      this.pinLoading = false;
    },
    getFacilityTitle(code) {
      const facility = FacilityRepository.get(code);
      return facility ? facility.name : "";
    },
    getUnitTitle(code, type) {
      const facility = FacilityRepository.get(code);
      if (facility) {
        const unitType = facility.unit_types.find(unit => unit.typeName === type);
        return unitType ? unitType.name : "";
      }
      return "";
    },
    hasEndDate(facilityCode, unit) {
      let end = this.invoices
          .filter(invoice => invoice.siteCode === facilityCode && invoice.objectIds === unit && !invoice.outstanding && invoice.invoiceType !== 9)
          .reduce(
              (end, invoice) => {
                const endDate = invoice.rows.reduce((end, row) => end > row.endDate ? end : row.endDate, "");
                return end > endDate ? end : endDate;
              },
              ""
          );
      if (!end) {
        return false;
      }
      this.expires[facilityCode + "/" + unit] = date.parse(end);
      return true;
    },
    isUnitExpired(facilityCode, unit) { // eslint-disable-line no-unused-vars
      let threshold = new Date();
      threshold.setDate(threshold.getDate() + 3);
      threshold.setHours(0, 0, 0);
      return threshold >= this.expires[facilityCode + "/" + unit];
    },
    async changeSubscription(item) {
      this.$refs[`switch-${item.contractNumber}-${item.siteCode}`][0].lazyValue = true;
      this.subscription = item.subscription;
      this.subscriptionDialog = true;
    },
    async cancelSubscription() {
      this.loading = true;
      await CustomerRepository.removeSubscription(this.subscription.id);
      this.subscriptionDialog = false;
      this.loading = false;
      this.subscription.next = null;
    },
    async cancelUnit() {
      this.loading = true;
      await CustomerRepository.removeUnit(this.unit);
      this.unitDialog = false;
      this.loading = false;
      this.unitConfirmDialog = true
    },
    async downloadContract(facilityCode, id) {
      await CustomerRepository.download({url: `${CONFIG.api.profile.units}/${facilityCode}/contract-${id}.pdf`, name: `contract-${id}.pdf`})
    },
    async downloadInsurance(facilityCode, id) {
      await CustomerRepository.download({url: `${CONFIG.api.profile.units}/${facilityCode}/insurance-${id}.pdf`, name: `insurance-${id}.pdf`})
    }
  },
};
</script>
