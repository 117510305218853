<template>
  <v-dialog
      :value="value"
      :max-width="$vuetify.breakpoint.xsOnly ? 'auto' : maxWidth"
      @input="input"
      :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : 'dialog-transition'"
      :content-class="`pr-dialog ${noFull ? 'no-fullscreen' : 'fullscreen'} ${$vuetify.breakpoint.xsOnly ? 'mobile' : ''}`"
  >
    <slot></slot>
    <template #activator="activatorProps">
      <slot name="activator" :on="activatorProps.on" :attrs="activatorProps.attrs"></slot>
    </template>
  </v-dialog>
</template>
<style scoped>
.mobile.pr-dialog {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  margin: 0 !important;
  height: auto !important;
  position: fixed !important;
  overflow-y: auto !important;
  bottom: 0 !important;
  left: 0 !important;
  transition-duration: .5s;
  /*transition: .5s cubic-bezier(.25,.8,.25,1) !important;*/
}

.mobile.fullscreen {
  height: 95% !important;
  max-height: 95% !important;
}

.pr-dialog.fullscreen.mobile > * {
  height: 100%;
}
</style>
<script>

export default {
  name: "PrDialog",
  props: {
    value: Boolean,
    noFull: Boolean,
    maxWidth: Number,
    activator: Object,
  },
  methods: {
    input(e) {
      this.show = e;
      this.$emit("input", e);
    },
  },
};
</script>
