var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.units && _vm.units.length)?_c('div',[_c('p',{staticClass:"col-12"},[_vm._v("Счета выставляются автоматически за 5 дней до окончания срока аренды. Вы можете оплатить счет заранее — позвоните в офис или "),_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.customInvoice($event)}}},[_vm._v("запросите онлайн")]),_vm._v(" и мы подготовим счет на оплату.")]),_c('dialog-card',{attrs:{"max-width":"600px"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Запрос счета ")]},proxy:true},{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","outlined":"","loading":_vm.loading,"disabled":_vm.loading},on:{"click":_vm.requestCustomInvoice}},[_vm._v(" Отправить ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.customInvoiceDialog = false; _vm.error = false}}},[_vm._v(" Отменить ")])]},proxy:true}],null,false,2461723575),model:{value:(_vm.customInvoiceDialog),callback:function ($$v) {_vm.customInvoiceDialog=$$v},expression:"customInvoiceDialog"}},[_c('pr-select',{attrs:{"items":_vm.requestFacilities,"item-text":"title","item-value":"code","label":"Склад"},model:{value:(_vm.facilityCode),callback:function ($$v) {_vm.facilityCode=$$v},expression:"facilityCode"}}),_c('pr-select',{attrs:{"items":_vm.unitsForFacility,"item-text":"unit","item-value":"unit","label":"Номер бокса"},model:{value:(_vm.unit),callback:function ($$v) {_vm.unit=$$v},expression:"unit"}}),_c('v-text-field-phonemask',{attrs:{"label":"Телефон для связи","options":{
          inputMask: '+# (###) ###–##–##',
          outputMask: '###########',
          countryCode: '7',
          empty: null,
          applyAfter: false,
          alphanumeric: false,
          lowerCase: false,
        },"type":"tel","required":"","rules":[function (v) { return !!v || v && !!v.trim() || 'Обязательное поле'; }, function (v) { return v && v.length === 18 || 'Неверный формат'; }]},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('pr-select',{attrs:{"items":_vm.periods,"label":"На срок","required":"","rules":[function (v) { return !!v || 'Обязательное поле'; }]},model:{value:(_vm.period),callback:function ($$v) {_vm.period=$$v},expression:"period"}}),_c('p',[_vm._v("Уведомление о выставлении счета поступит на email, указанный в договоре, после этого счет появится в Вашем личном кабинете")]),_c('alert-banner',{model:{value:(_vm.error),callback:function ($$v) {_vm.error=$$v},expression:"error"}})],1),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.confirmDialog),callback:function ($$v) {_vm.confirmDialog=$$v},expression:"confirmDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Запрос отправлен ")]),_c('v-card-text',[_c('p',[_vm._v("Счёт будет выставлен в рабочие часы офиса и появится в личном кабинете")])]),_c('div',{staticClass:"py-3 px-6"},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){_vm.confirmDialog = false}}},[_vm._v(" Хорошо ")])],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }