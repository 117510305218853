<template>
  <dialog-card max-width="400px" v-model="dialog">
    <template #title>
      Изменение пароля
    </template>
    <template #activator="{ on, attr}">
      <v-btn
          icon
          v-on="on"
          v-bind="attr"
      >
        <v-icon>mdi-key-variant</v-icon>
      </v-btn>
    </template>
    <v-form ref="form">
      <v-text-field
          v-model="password"
          label="Новый пароль"
          :type="showPass ? 'text' : 'password'"
          :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPass = !showPass"
          :rules="[v => !!v && !!v.trim() || 'Обязательное поле']"
          required
      />
      <v-text-field
          v-model="confirmPassword"
          label="Подтверждение пароля"
          :type="showPass ? 'text' : 'password'"
          :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPass = !showPass"
          :rules="[v => !!v && !!v.trim() || 'Обязательное поле', v => v === this.password || 'Пароли не совпадают']"
          required
      />
      <alert-banner v-model="error"/>
    </v-form>
    <template #actions>
      <v-btn
          color="primary"
          outlined
          @click="submit"
          :loading="loading"
          :disabled="loading">
        Сохранить
      </v-btn>
      <v-btn
          color="primary"
          text
          @click="dialog = false"
      >
        Отменить
      </v-btn>
    </template>
  </dialog-card>
</template>
<script>
import DialogCard from "@/components/DialogCard";
import AlertBanner from "@/components/AlertBanner";
import CustomerRepository from "@/repository/customer";

export default {
  name: "ChangePassword",
  components: {AlertBanner, DialogCard},
  data() {
    return {
      dialog: false,
      loading: false,
      password: "",
      showPass: false,
      confirmPassword: "",
      error: "",
    };
  },
  methods: {
    async submit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;
      try {
        await CustomerRepository.updatePassword({password: this.password, password_confirmation: this.confirmPassword});
        this.dialog = false;
      } catch (e) {
        this.error = e.response.data.message;
      }
      this.loading = false;
    },
  },
};
</script>
