<template>
  <v-container class="pa-0">
    <v-card>
      <v-card-title>Произошла ошибка</v-card-title>
      <v-card-text>
        <p>Невозможно провести оплату.</p>
      </v-card-text>
      <div class="py-3 px-6">
        <v-btn @click="$router.push({name: 'Profile'})" text color="primary">В личный кабинет</v-btn>
      </div>
    </v-card>
  </v-container>
</template>
<script>

export default {
  name: "Fail",
  data() {
    return {
      attrs: {
        elevation: 2,
      },
    };
  },
};
</script>
