<template>
  <div v-if="units && units.length">
    <p class="col-12">Счета выставляются автоматически за 5 дней до окончания срока аренды. Вы можете оплатить счет заранее &mdash; позвоните в офис или <a href="#" @click.stop.prevent="customInvoice">запросите онлайн</a> и мы подготовим счет на оплату.</p>
    <dialog-card
        v-model="customInvoiceDialog"
        max-width="600px"
    >
      <template #title>
        Запрос счета
      </template>
      <template #actions>
        <v-btn
            color="primary"
            outlined
            @click="requestCustomInvoice"
            :loading="loading"
            :disabled="loading">
          Отправить
        </v-btn>
        <v-btn
            color="primary"
            text
            @click="customInvoiceDialog = false; error = false"
        >
          Отменить
        </v-btn>
      </template>
      <pr-select
          :items="requestFacilities"
          v-model="facilityCode"
          item-text="title"
          item-value="code"
          label="Склад"
      />
      <pr-select
          :items="unitsForFacility"
          v-model="unit"
          item-text="unit"
          item-value="unit"
          label="Номер бокса"
      />
      <v-text-field-phonemask
          v-model="phone"
          label="Телефон для связи"
          v-bind:options="{
            inputMask: '+# (###) ###–##–##',
            outputMask: '###########',
            countryCode: '7',
            empty: null,
            applyAfter: false,
            alphanumeric: false,
            lowerCase: false,
          }"
          type="tel"
          required
          :rules="[v => !!v || v && !!v.trim() || 'Обязательное поле', v => v && v.length === 18 || 'Неверный формат']"
      />
      <pr-select
          v-model="period"
          :items="periods"
          label="На срок"
          required
          :rules="[v => !!v || 'Обязательное поле']"
      />
      <p>Уведомление о выставлении счета поступит на email, указанный в договоре, после этого счет появится в Вашем личном кабинете</p>
      <alert-banner v-model="error"/>
    </dialog-card>
    <v-dialog v-model="confirmDialog" max-width="600px">
      <v-card>
        <v-card-title class="headline">
          Запрос отправлен
        </v-card-title>
        <v-card-text>
          <p>Счёт будет выставлен в рабочие часы офиса и появится в личном кабинете</p>
        </v-card-text>
        <div class="py-3 px-6">
          <v-btn
              color="primary"
              outlined
              @click="confirmDialog = false">
            Хорошо
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<style scoped>
.date-field {
  flex: 0 0 100%;
  max-width: 100%;
}

@media (min-width: 600px) {
  .date-field {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
</style>
<script>
import {mapState} from "vuex";
import PrSelect from "@/components/PrSelect";
import DialogCard from "@/components/DialogCard";
import AlertBanner from "@/components/AlertBanner";
import FacilityRepository from "@/repository/facility";
import UnitRepository from "@/repository/unit";
import CustomerRepository from "@/repository/customer";

export default {
  name: "CustomInvoice",
  components: {
    AlertBanner,
    DialogCard,
    PrSelect,
  },
  data() {
    return {
      units: [],
      facilityCode: "",
      unit: "",
      customInvoiceDialog: false,
      confirmDialog: false,
      loading: false,
      error: "",
      phone: "",
      period: "",
      periods: ["1 неделя", "1 месяц", "3 месяца", "6 месяцев", "11 месяцев"],
    };
  },
  async mounted() {
    await FacilityRepository.list();
    this.units = await UnitRepository.list();

  },
  computed: {
    ...mapState({
      user: state => state.profile.user,
    }),
    requestFacilities() {
      const facilities = this.units.reduce((list, curr) => {
        list[curr.siteCode] = true;
        return list;
      }, {});
      return Object.keys(facilities).map(code => ({code, title: this.getFacilityTitle(code)}));
    },
    unitsForFacility() {
      return this.units.filter(unit => unit.siteCode === this.facilityCode);
    },
  },
  methods: {
    customInvoice() {
      this.facilityCode = this.requestFacilities[0].code;
      this.customInvoiceDialog = true;
      this.phone = this.phone || `+${this.user.phone}`;
    },
    async requestCustomInvoice() {
      this.loading = true;
      if (!this.facilityCode || !this.unit || !this.phone || !this.period) {
        this.error = "Необходимо заполнить все поля";
      } else {
        this.error = "";
        try {
          await CustomerRepository.requestCustomInvoice({
            facility: this.getFacilityTitle(this.facilityCode),
            unit: this.unit,
            phone: this.phone,
            period: this.period,
          });
        } catch (e) {
          this.error = e.response.data.message;
        }
        this.confirmDialog = true;
        this.customInvoiceDialog = false;
      }
      this.loading = false;
    },
    getFacilityTitle(code) {
      const facility = FacilityRepository.get(code);
      return facility ? facility.name : "";
    },
  },
};
</script>
