<template>
  <div>
    <slot name="header"/>
    <v-pagination v-model="value" :length="pages" @input="$emit('input', value)"></v-pagination>
    <v-list>
      <div v-for="(item, idx) in list"
           :key="idx"
      >
        <slot name="item" :item="item"/>
      </div>
    </v-list>
    <v-pagination v-model="value" :length="pages" @input="$emit('input', value)"></v-pagination>
    <slot name="footer"/>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    value: Number,
    pages: Number,
    list: Array,
  },
};
</script>

<style scoped>

</style>
