<template>
  <div>
    <pagination
        v-model="page"
        :list="subscriptions.list"
        :pages="subscriptions.pages"
        @input="loadList"
    >
      <template #header>
        <search
            class="d-inline-block col-12 col-sm-6 col-md-5 col-lg-4"
            v-model="query"
            @input="search"
        >
          <template #extensions>
            <v-switch
                class="col-12"
                v-model="active"
                @change="search"
                label="Только активные"
            />
          </template>
        </search>
        <p v-if="!loading">Найдено {{subscriptions.total}} подписок.</p>
      </template>
      <template #item="{ item }">
        <v-list-item>
          <v-list-item-avatar>
            <img :src="`${PROFILE_DOMAIN}/img/${item.payment_provider}.svg`" title="провайдер" alt="провайдер">
          </v-list-item-avatar>
          <v-list-item-content class="box-list">
            <v-list-item-title>
              {{ item.facility_code }}/{{ item.unit }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ format.toCurrency(item.amount) }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              {{ item.period }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="d-flex flex-column">
              <div>{{ item.firstname }}</div>
              <div>{{ item.email }}</div>
              <div>+{{ item.phone }}</div>
              <div>id: {{ item.store_id }}</div>
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              <v-chip small :color="`grey ${item.next ? 'lighten-3' : 'lighten-5'}`">
                <span class="d-none d-md-inline-block pr-1">следующий платеж:</span>
                <span>{{ item.next ? date.isoToHuman(item.next) : "отключен" }}</span>
              </v-chip>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-btn
              icon
              @click="edit(item)"
          >
            <v-icon>mdi-pencil-outline</v-icon>
          </v-btn>
        </v-list-item>
      </template>
    </pagination>
    <dialog-card
        v-model="dialog"
        max-width="700px"
    >
      <template #title>
        Изменение подписки
      </template>
      <template #actions>
        <v-btn
            color="primary"
            outlined
            @click="request"
            :loading="loading"
            :disabled="loading">
          Сохранить
        </v-btn>
        <v-btn
            color="primary"
            text
            @click="dialog = false"
        >
          Отменить
        </v-btn>
      </template>
      <v-form ref="form">
        <div class="text--secondary" v-if="createdAt">Дата создания: {{ date.isoToHuman(createdAt, true) }}</div>
        <div class="d-flex flex-column flex-sm-row">
          <v-text-field
              v-model="facilityCode"
              label="Склад"
              :rules="[v => !!v || 'Необходимо указать склад']"
              class="col-12 col-sm-6 px-0"
              disabled
          />
          <v-text-field
              v-model="unit"
              label="Номер бокса"
              :rules="[v => !!v || 'Необходимо указать номер бокса']"
              class="col-12 col-sm-6 px-0"
          />
        </div>
        <div class="d-flex flex-column flex-sm-row">
          <v-text-field
              v-model="amount"
              label="Сумма"
              :rules="[v => !!v || 'Сумма должна быть больше 0 р.']"
              class="col-12 col-sm-6 px-0"
              :disabled="paymentProvider === 'cloud'"
          ></v-text-field>
          <pr-menu
              v-model="next"
              offset-y
              transition="scale-transition"
              max-width="290px"
              min-width="290px"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                  readonly
                  v-model="humanDateNext"
                  label="Следующий платеж"
                  class="col-12 col-sm-6 px-0"
                  persistent-hint
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  clearable
                  :disabled="paymentProvider === 'cloud'"
                  @click:clear="dateNext = null"
              ></v-text-field>
            </template>
            <v-date-picker
                :first-day-of-week="1"
                locale="ru"
                v-model="dateNext"
                no-title
                color="primary"
                @input="next = false"
            ></v-date-picker>
          </pr-menu>
        </div>
        <div class="d-flex flex-column flex-md-row">
          <v-text-field
              v-model="period"
              label="Период"
              type="number"
              :disabled="paymentProvider === 'cloud'"
          ></v-text-field>
          <pr-select
              v-model="interval"
              label="Интервал"
              :items="intervals"
              item-value="value"
              item-text="title"
              :disabled="paymentProvider === 'cloud'"
          />
        </div>
      </v-form>
    </dialog-card>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import date from "@/lib/date";
import format from "@/lib/format";
import Pagination from "@/components/Admin/components/Pagination";
import Search from "@/components/Admin/components/Search";
import DialogCard from "@/components/DialogCard";
import PrMenu from "@/components/PrMenu";
import PrSelect from "@/components/PrSelect";

export default {
  name: "Subscriptions",
  components: {PrSelect, PrMenu, Search, DialogCard, Pagination},
  data() {
    return {
      date,
      format,
      dialog: false,
      loading: false,
      page: 1,
      active: true,
      subscription: {},
      query: "",
      intervals: [
        {
          value: "week",
          title: "Неделя",
        },
        {
          value: "month",
          title: "Месяц",
        },
      ],
      next: false,
      dateNext: null,
      amount: 0,
      subscriptionId: null,
      createdAt: null,
      facilityCode: "",
      unit: "",
      paymentProvider: "",
      subscriptions: {},
      PROFILE_DOMAIN: process.env.VUE_APP_PROFILE_DOMAIN,
    };
  },
  async mounted() {
    await this.loadList();
  },
  computed: {
    humanDateNext() {
      return this.dateNext ? date.isoToHuman(this.dateNext) : null;
    },
  },
  methods: {
    search() {
      this.page = 1;
      this.loadList();
    },
    edit(item) {
      this.dialog = true;
      this.subscriptionId = item.id;
      this.createdAt = item.created_at;
      this.dateNext = item.next;
      this.amount = item.amount;
      this.unit = item.unit;
      this.facilityCode = item.facility_code;
      this.paymentProvider = item.payment_provider;
      const period = item.period?.match("^(\\d+) (.+)");
      if (period) {
        this.interval = period[2];
        this.period = period[1];
      }
    },
    async request() {
      this.loading = true;
      if (this.$refs.form.validate()) {
        const subscription = this.subscriptions?.list?.find(item => item.id === this.subscriptionId);
        if (subscription) {
          subscription.next = this.dateNext;
          subscription.amount = this.amount;
          subscription.period = `${this.period} ${this.interval}`;
          subscription.unit = this.unit;
          subscription.facility_code = this.facilityCode;
          await this.editSubscription({
            id: subscription.id,
            data: {
              period: subscription.period,
              amount: subscription.amount,
              next: subscription.next,
              facility_code: subscription.facility_code,
              unit: subscription.unit,
            },
          });
        }
      }
      this.loading = false;
      this.dialog = false;
    },
    async loadList() {
      this.loading = true;
      this.subscriptions = await this.loadSubscriptions({
        page: this.page,
        query: this.query,
        active: this.active ? 1 : 0,
      });
      this.loading = false;
    },
    ...mapActions({
      loadSubscriptions: "admin/listSubscriptions",
      editSubscription: "admin/editSubscription",
    }),
  },
};
</script>
