<template>
  <v-form ref="form" @submit.stop.prevent="restore" v-model="valid">
    <v-subheader>Новый пароль</v-subheader>
    <v-text-field
        v-model="email"
        label="e-mail"
        :rules="[v => !!v && !!v.trim() || 'Обязательное поле']"
        required
    ></v-text-field>
    <v-text-field
        v-model="password"
        label="Новый пароль"
        type="password"
        :rules="[v => !!v && !!v.trim() || 'Обязательное поле', v => (v && v.length > 3) || 'Пароль не может быть меньше 3 символов']"
        required
    ></v-text-field>
    <v-text-field
        v-model="confirmPassword"
        label="Подтверждение пароля"
        type="password"
        :rules="[v => !!v && !!v.trim() || 'Обязательное поле', v => v === this.password || 'Пароли не совпадают']"
        required
    ></v-text-field>
    <alert-banner v-model="error"/>
    <v-divider></v-divider>
    <v-container class="px-0">
      <v-btn color="primary" type="submit" outlined :loading="loading" :disabled="loading">
        Изменить
      </v-btn>
    </v-container>
    <v-container>
      <v-row>
        <v-col cols="12">
          <router-link :to="{name: 'Login'}">На страницу авторизации</router-link>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
import AlertBanner from "@/components/AlertBanner";
import CustomerRepository from "@/repository/customer";
import {mapActions} from "vuex";

export default {
  name: "RestorePassword",
  components: {AlertBanner},
  data() {
    return {
      loading: false,
      error: "",
      email: "",
      password: "",
      confirmPassword: "",
      valid: true,
    };
  },
  methods: {
    ...mapActions({
      apiLogin: "profile/login",
    }),
    async restore() {
      this.valid = this.$refs.form.validate();
      this.loading = true;
      try {
        if (this.valid) {
          await CustomerRepository.resetPassword({
            email: this.email,
            password: this.password,
            password_confirmation: this.confirmPassword,
            token: this.$route.params.token,
          });
          await this.apiLogin({
            email: this.email,
            password: this.password,
          });
          await this.$router.push({name: "Profile"});
        }
      } catch (e) {
        this.error = e.response.data.message;
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped>

</style>
