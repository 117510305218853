<template>
  <v-form ref="form" @submit.stop.prevent="restore" v-model="valid">
    <v-subheader>Восстановление пароля</v-subheader>
    <div v-show="!send">
      <v-text-field
          v-model="email"
          label="e-mail"
          :rules="[v => !!v && !!v.trim() || 'Обязательное поле']"
          required
      ></v-text-field>
      <alert-banner v-model="error"/>
      <v-divider></v-divider>
      <v-container class="px-0">
        <v-btn color="primary" type="submit" outlined :loading="loading" :disabled="loading">
          Отправить письмо
        </v-btn>
      </v-container>
      <v-container>
        <v-row>
          <v-col cols="12">
            <router-link :to="{name: 'Login'}">На страницу авторизации</router-link>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div v-show="send">
      <p>На указанный адрес отправлено письмо с инструкцией по восстановлению доступа.</p>
      <a :href="MAIN_DOMAIN" title="'PROSTOR">На главную</a>
    </div>
  </v-form>
</template>
<script>
import AlertBanner from "@/components/AlertBanner";
import CustomerRepository from "@/repository/customer";

export default {
  name: "ForgotPassword",
  components: {AlertBanner},
  data() {
    return {
      loading: false,
      send: false,
      error: "",
      email: "",
      valid: true,
      MAIN_DOMAIN: process.env.VUE_APP_MAIN_DOMAIN,
    };
  },
  methods: {
    async restore() {
      this.valid = this.$refs.form.validate();
      this.loading = true;
      try {
        if (this.valid) {
          await CustomerRepository.forgotPassword({
            email: this.email,
          });
          this.send = true;
        }
      } catch (e) {
        this.error = e.response.data.message;
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped>

</style>
