<template>
  <v-container class="pa-0">
    <v-card v-if="unpaid">
      <v-card-title>Подождите, идет обработка платежа. Не закрывайте страницу</v-card-title>
      <v-skeleton-loader
          v-bind="attrs"
          type="article, actions"
      ></v-skeleton-loader>
    </v-card>
    <v-card v-if="!unpaid && payment.order">
      <v-card-title>Ваш заказ оплачен!</v-card-title>
      <v-card-text v-if="payment.pinNew">
        <p>Ваш пин-код для доступа на склад <span class="text-h6">{{ payment.address }}</span>: <span class="text-h5">*{{ payment.pin }}#</span>.</p>
        <p>На указанный при регистрации номер телефона отправлено сообщение с этим кодом.</p>
      </v-card-text>
      <v-card-text v-else>
        <p>Ваш пин-код: <span class="text-h5">*{{ payment.pin }}#</span></p>
        <p>Пин-код действителен для всех Ваших боксов по адресу <span class="text-h6">{{ payment.address }}</span>.</p>
      </v-card-text>
      <div class="py-3 px-6">
        <v-btn @click="$router.push({name: 'Profile'})" text color="primary">В личный кабинет</v-btn>
      </div>
    </v-card>
    <v-card v-if="!unpaid && !payment.order">
      <v-card-title>Произошла ошибка обработки</v-card-title>
      <v-card-text>
        <p>Позвоните нам, мы все исправим.</p>
      </v-card-text>
      <div class="py-3 px-6">
        <v-btn @click="$router.push({name: 'Profile'})" text color="primary">В личный кабинет</v-btn>
      </div>
    </v-card>
  </v-container>
</template>
<script>
import CustomerRepository from "@/repository/customer";

export default {
  name: "Success",
  data() {
    return {
      attrs: {
        elevation: 2,
      },
      unpaid: true,
      payment: {},
    };
  },
  async mounted() {
    const sleep = ms => new Promise(r => setTimeout(r, ms));
    for (;;) {
      try {
        this.payment = await CustomerRepository.pay(document.location.pathname + document.location.search);
      } catch (e) {
        if (e.response.status === 409) {
          await sleep(1000);
          continue;
        }
      }
      break;
    }
    this.unpaid = false;
  },
};
</script>
