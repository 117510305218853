<template>
  <main-layout>
    <template slot="content">
      <success v-if="type === 'success'"></success>
      <fail v-if="type === 'fail'"></fail>
    </template>
  </main-layout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";
import Success from "@/components/Sber/Success";
import Fail from "@/components/Sber/Fail";

export default {
  name: "Payment",
  components: {
    Fail,
    MainLayout,
    Success,
  },
  props: {
    type: String,
  },
};
</script>
