<template>
  <main-layout>
    <template slot="content">
      <v-tabs v-model="tab" v-if="user.role">
        <v-tab>Личный кабинет</v-tab>
        <v-tab>Пользователи</v-tab>
        <v-tab v-if="user.role.admin || user.role.receipt || user.role.buh">Подписки</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-if="user.email">
          <list-units></list-units>
          <list-invoices
              :entityId="$route.params.entity === 'invoice' ? $route.params.entityId : null"
              :user="user"
          />
          <list-documents/>
          <custom-invoice></custom-invoice>
        </v-tab-item>
        <v-tab-item v-if="user.role">
          <users></users>
        </v-tab-item>
        <v-tab-item v-if="user.role && (user.role.admin || user.role.receipt || user.role.buh)">
          <subscriptions></subscriptions>
        </v-tab-item>
      </v-tabs-items>
    </template>
  </main-layout>
</template>
<style>
hr {
  margin: 0;
}

@media screen and (min-width: 600px) {
  .v-list-item__content.box-list > * {
    flex: 1 0;
  }
}

.pdf-link {
  text-decoration: none;
}
</style>
<script>
import MainLayout from "@/layouts/MainLayout";
import ListUnits from "@/components/ListUnits";
import ListInvoices from "@/components/ListInvoices";
import CustomInvoice from "@/components/CustomInvoice";
import Subscriptions from "@/components/Admin/Subscriptions";
import {mapState, mapActions} from "vuex";
import Users from "@/components/Admin/Users";
import ListDocuments from "@/components/ListDocuments";

export default {
  name: "Profile",
  components: {
    ListDocuments,
    Users,
    CustomInvoice,
    MainLayout,
    ListUnits,
    ListInvoices,
    Subscriptions,
  },
  async mounted() {
    await this.me();
    if (!this.user.email) {
      this.showLogin();
    }
    if (!this.user.role) {
      this.tab = 0;
    }
    document.addEventListener('user-unauth', this.showLogin);
  },
  destroyed() {
    document.removeEventListener('user-unauth', this.showLogin);
  },
  computed: {
    ...mapState({
      user: state => state.profile.user,
      page: state => state.admin.page,
    }),
    tab: {
      get() {
        return this.page;
      },
      set(value) {
        this.$store.commit("admin/setPage", value);
      },
    },
  },
  methods: {
    ...mapActions({
      me: "profile/me",
    }),
    showLogin() {
      this.$store.commit("profile/setUser", {});
      this.$store.commit("profile/setToken", null);
      this.$router.push({name: "Login"});
    },
  },
};
</script>
