<template>
  <v-form @submit.stop.prevent="restore" ref="form" v-model="valid">
    <v-subheader>Создание пароля</v-subheader>
    <div v-show="!send">
      <v-text-field
          v-model="email"
          label="Логин"
          hint="Обычно email"
          required
          :rules="[v => !!v && !!v.trim() || 'Обязательное поле']"
      ></v-text-field>
      <v-text-field
          v-model="customerId"
          label="Ваш ID"
          hint="от 3 до 6 цифр"
          required
          :rules="[v => !!v && !!v.trim() || 'Обязательное поле']"
      >
        <template v-slot:append-outer>
          <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
          >
            <template v-slot:activator="{on}">
              <v-btn icon v-on="on">
                <v-icon>mdi-help-circle-outline</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <div>№2019-SS-<span class="primary">001</span>-1</div>
                <div>Дата 09.12.2019</div>
              </v-card-title>
              <v-card-text>Номер вашего ID содержится в номере договора</v-card-text>
            </v-card>
          </v-menu>
        </template>
      </v-text-field>
      <alert-banner v-model="error"/>
      <v-divider></v-divider>
      <v-container class="px-0">
        <v-btn color="primary" type="submit" outlined :loading="loading" :disabled="loading">
          Создать пароль
        </v-btn>
      </v-container>
      <v-container>
        <v-row>
          <v-col cols="12">
            <router-link :to="{name: 'Login'}">На страницу авторизации</router-link>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div v-show="send">
      <p>На указанный адрес отправлено письмо с инструкцией по получению доступа.</p>
      <a :href="MAIN_DOMAIN" title="'PROSTOR">На главную</a>
    </div>
  </v-form>
</template>
<script>
import AlertBanner from "@/components/AlertBanner";
import CustomerRepository from "@/repository/customer";

export default {
  name: "RestoreUser",
  components: {AlertBanner},
  data() {
    return {
      loading: false,
      send: false,
      error: "",
      email: "",
      customerId: "",
      valid: true,
      MAIN_DOMAIN: process.env.VUE_APP_MAIN_DOMAIN,
    };
  },
  methods: {
    async restore() {
      this.valid = this.$refs.form.validate();
      this.loading = true;
      try {
        if (this.valid) {
          await CustomerRepository.restoreCustomer({
            email: this.email,
            store_id: this.customerId,
          });
          this.send = true;
        }
      } catch (e) {
        this.error = e.response.data.message;
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped>

</style>
