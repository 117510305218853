import CONFIG from "@/config";
import api from "@/api/axiosFactory";

let list;

export default {
    async list() {
        return list ||= new Promise(r => r(api({url: CONFIG.api.profile.subscriptions}))).then(request => request.data);
    }
};
