<template>
  <v-form
      @submit.stop.prevent="submit"
  >
    <v-text-field
        label="Поиск"
        v-model="value"
        clearable
        @click:clear="clear"
    >
      <template #prepend>
        <v-btn
            icon
            type="submit"
            :disabled="loading"
            :loading="loading"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </template>
    </v-text-field>
    <slot name="extensions"/>
  </v-form>
</template>

<script>
export default {
  name: "Search",
  props: {
    value: String,
  },
  methods: {
    submit() {
      this.$emit('input', this.value);
    },
    clear() {
      this.$emit('input', '');
    }
  },
};
</script>
